<template>
    <div>
        查看消息
        <input type="text">
    </div>
</template>

<script>
export default {
    name: 'msg',
}
</script>

<style scoped>

</style>
